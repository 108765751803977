import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import TechCard from "./TechCard";
import TypeTech from "./TypeTech";

function About() {
  return (
    <Container fluid className="about-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={10}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
                          <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
                Technologies in <strong className="cyan"> <a href="https://finance.mrfreire.net">finance.mrfreire.net</a> </strong>
            </h1>
                            <div style={{ padding: 150, textAlign: "center" }}>
                <TypeTech />
              </div>

            <TechCard />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default About;
