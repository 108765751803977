import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import CourseCard from "./CourseCard";
import data from "./courses.json"

function Courses() {


    let courses = [];
    courses = (data["certifications"])
    fetch(data)
        .then(response => {
            //console.log(response); // log the response
            return response.json();
        })
        .then(data => {
            //console.log(data); // log the parsed JSON data
            // do something with the data
        })
        .catch(error => {
            //console.error('Error reading file:', error);
        });

    return (
        <Container fluid className="project-section">
            <Container>
                <h1 className="project-heading">
                    My Recent Courses
                </h1>

                <Row style={{justifyContent: "center"}}>


                    {courses.map((course, index) => (
                        <Col lg={3} md={4} sm={6} xs={12} className="course-card">
                            <CourseCard
                                isBlog={false}
                                title={course.title}
                                university={course.university}
                                link={course.link}
                                imgPath={course.image}
                            />
                        </Col>
                    ))}


                    


                </Row>
            </Container>
        </Container>
    );
}

export default Courses;
