import React from "react";
import Typewriter from "typewriter-effect";

function TypeTech() {
    return (
        <Typewriter
            options={{
                strings: [
                    "Python",
                    "Flask",
                    "AWS",
                    "MongoDB",
                    "Gunicorn",
                    "nginx",
                    "Javascript"
                ],
                autoStart: true,
                loop: true,
                deleteSpeed: 50,
            }}
        />
    );
}

export default TypeTech;
