import React from "react";
import Card from "react-bootstrap/Card";

function LimitationsCard() {
    return (<Card className="quote-card-view">
        <Card.Body>
            <div style={{textAlign: 'left'}}>
                <h2>Introduction</h2>
                <blockquote className="small">
                    <p>This website has been built in my spare time. The website is also not optimized for mobile
                        devices
                        and the best experience is shown in the desktop browsers.
                        There are several limitations that must be known related to the data storage. For economic
                        purposes, some data is restricted in order to guarantee that the storage does not reach a
                        certain limit.
                    </p>

                    <h2>Performance</h2>
                    <p>
                        The website is built using the AWS free-tier. For that reason, the performance of the website is
                        not optimal when there are many simultaneous users. In addition to that, the website is not
                        optimized for mobile devices and the best experience is shown in the desktop browsers.
                    </p>

                    <div>

                        <h2>Missing Data</h2>
                        <p> Not all data is available for all assets. Work is in progress to retrieve and calculate as
                            much data as
                            possible from different sources. There is one script collecting the latest data and feeding
                            the database
                            every day.
                        </p>
                    </div>

                    <div>

                        <h2>ISINs</h2>
                        <p> The algorithm for retrieving the ISIN of the different assets needs still some improvements.
                            For that reason, some ISINs for some non-popular assets are not correct. Some
                            wrong ISINs are also due to the initial loaded data, which used a different algorithm.
                            Because
                            of the old rules, some crypto assets may be also shown with a specific ISIN or some US
                            assets are displayed
                            with the European ISIN.
                        </p>
                    </div>

                    <div>

                        <h2>Expenses for ETFs/Mutual Funds</h2>
                        <p>In some cases, it is not possible to retrieve the expenses for each investment fund or ETF.
                            Another rule must be created in order to fetch this information from other sources. Work is
                            in
                            progress in order to find additional sources to retrieve this information. For that reason,
                            in those funds
                            with an expense ratio of 0.00%, the expense ratio is not known.</p>
                    </div>

                    <div>
                        <h2>Wrong prices</h2>
                        <p>Although for most traded securities you will see that the prices are correct, there are some
                            assets, mainly mutual funds, for which the pricing information may be inaccurate. This
                            prices
                            may lead to wrong performance results and there are some mechanisms to ignore these assets
                            from
                            the different calculations.
                        </p>
                    </div>

                    <div>
                        <h2>Unlisted assets</h2>
                        <p>For historic reasons, some assets are still shown in the database, although they are not
                            traded anymore. This
                            is due to the fact that the database is not cleaned up from time to time. This will be done
                            in the future.
                        </p>
                    </div>

                </blockquote>
            </div>
        </Card.Body>
    </Card>);
}

export default LimitationsCard;
