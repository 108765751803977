import React, {Component} from "react";
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import data from "./movies.json"
import TableMovie from "./TableMovie";
class Movies extends Component {
    render() {
        return (
           <Container fluid className="project-section">
                <Container>
                    <h1 className="project-heading">
                        <strong>Movies Watchlist</strong>
                    </h1>
                    <Row className="justify-content-center" style={{ paddingBottom: "10px" }}>
                        <Col md={12} sm={6}>
                            <div style={{ overflow: "auto", textAlign: "center" }}>
                                <TableMovie data={data} style={{ color: "white" }} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        );
    }
}

export default Movies;