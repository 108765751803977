import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import RealmService from "../Realm/RealmService";
import CredentialsAPI from "../Credentials/CredentialsAPI";
import SetCard from "./SetCard";


const auctionsService = new RealmService('application-0-bouka');

const Sets = () => {
    let sets = [];
  const [queryResult, setQueryResult] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await auctionsService.initialize(     'application-0-bouka', CredentialsAPI.API_SETS);

      const mongodb = await auctionsService.getMongoClient();
      if (mongodb) {
        const collection = mongodb.db('Music').collection('Sets');

        // Perform queries or operations on the collection

        // Example: Fetch all documents from the collection
        const documents = await collection.find();
        const sorted_documents = documents.sort((a, b) => (a.date < b.date) ? 1 : -1)
        setQueryResult(sorted_documents);
      }
    };

    fetchData();
  }, []);


 return (
        <Container fluid className="project-section">
            <Container>
                <h1 className="project-heading">
                    Recent Sets
                </h1>
                <Row style={{justifyContent: "center", paddingBottom: "10px"}}>


                    {queryResult.map((set, index) => (
                        <Col lg={3} md={4} sm={6} xs={12}>
                            <SetCard
                                isBlog={false}
                                title={set.title}
                                artist={set.artist}
                                imgPath={set.image}
                                link={set.link}
                            />
                        </Col>
                    ))}


                </Row>
                <p>
                    <hr></hr>
                </p>
                <p>
                    Files generated using: <a href="https://github.com/vonfreiren/youtube-sets-downloader">youtube-sets-downloader</a>
                </p>
            </Container>
        </Container>
    );
}

export default Sets;
