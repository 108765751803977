import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import BookCard from "../Books/BookCard";
import ProjectCards from "./ProjectCards";

function Books() {

    const description_finance =  "The goal of this website is to help \"common people\" showing them information about\n" +
        "                                different\n" +
        "                                asset classes in order to educate them and help them protect their savings with their\n" +
        "                                right\n" +
        "                                choice.\n" +
        "                                This page should not be seen as financial advice at all." +
        "" +
        "                               If you are planning to invest, you can search for any asset here. Furthermore, you can\n" +
        "                                also use the RoboAdvisor feature with the desired investment amount and your\n" +
        "                                risk-aversion. In addition to the expected returns, it takes other factors such as\n" +
        "                                expenses, Morningstar analysis, or sustainability ratings"


    const description_nba = "The website provides information about NBA teams, players, and their market values. " +
        "It includes details such as the names of the teams, their market values in millions of dollars, and player information including their names, positions, heights, weights, dates of birth, and team affiliations" +
        "The market values of the players are calculated considering statistics such as points, rebounds, assists, steals, blocks, turnovers, and fouls, age, trend, consistency, and potential." +
        "With a machine learning model based on historic data, the statistics for the next season are predicted, including the market value of the players."

const description_uci = "The website provides information about UCI teams, players, and their market values. It includes details such as the names of the teams, their market values in millions of dollars, and player information including their names, positions, heights, weights, dates of birth, and team affiliations.\"}\n" +
    "The market value of the riders is calculated taking into account the wins, , age, trend, consistency, and potential."


 const description_blog = "The personal blog is a place where I share my thoughts and experiences. I write about topics such as finance, sports, and technology. I also write about my personal experiences and my life in general. Some of the topics I have written about are:" +
     "Finance, Sports, Technology, Personal Experiences, Life in General, and more."
    return (<Container fluid className="project-section">
            <Container>
                <h1 className="project-heading">
                    My Recent Projects
                </h1>
                <Row style={{justifyContent: "center", paddingBottom: "10px"}}>


                    <Col lg={4} md={6} sm={6} xs={12} className="book-card">
                        <ProjectCards
                            isBlog={false}
                            title={"Finance"}
                            author={"Javier Freire"}
                            description={description_finance}
                            link={"https://www.finance.mrfreire.net"}
                            imgPath={'https://mrfreire.net/img/posts/wealth/wealth.jpeg'}
                        />
                    </Col>


                    <Col lg={4} md={6} sm={6} xs={12} className="book-card">
                        <ProjectCards
                            isBlog={false}
                            title={"NBA"}
                            author={"Javier Freire"}
                            description={description_nba}
                            link={"https://www.nba.mrfreire.net"}
                            imgPath={'https://mrfreire.net/img/posts/mtb/nba.jpg'}
                        />
                    </Col>

                    <Col lg={4} md={6} sm={6} xs={12} className="book-card">
                        <ProjectCards
                            isBlog={false}
                            title={"Cycling"}
                            author={"Javier Freire"}
                            description={description_uci}
                            link={"https://www.cycling.mrfreire.net"}
                            imgPath={'https://mrfreire.net/img/posts/mtb/cycling.jpeg'}
                        />
                    </Col>


                    <Col lg={4} md={6} sm={6} xs={12} className="book-card">
                        <ProjectCards
                            isBlog={false}
                            title={"Blog"}
                            author={"Javier Freire"}
                                                        description={description_blog}

                            link={"https://www.mrfreire.net"}
                            imgPath={'https://mrfreire.net/img/posts/nfts/nfts.jpg'}
                        />
                    </Col>


                </Row>
            </Container>
        </Container>);
}

export default Books;
