import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import Type from "./Type";

function Home() {
    return (
        <section>
            <Container fluid className="home-section" id="home">
                <Container className="home-content">
                    <Row>
                        <Col md={12} className="home-header">
                            <h1 style={{padding: 40}} className="heading" style={{textAlign: 'center'}}>
                                Javier Freire Alvarez
                            </h1>

                            <div style={{padding: 20, textAlign: "center", alignContent: "center"}}>
                                <Type/>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </Container>

                       <Container fluid className="home-section" id="home">
                <Container className="home-content">
                    <Row>
                        <Col md={12} className="home-header">
                            <h6 className="heading">Website created by Javier Freire</h6>
                        </Col>

                        <Col md={12} className="home-body">
                            <p>
                                You can learn about different projects I have worked on, and also read my blog posts.
                            </p>

                        </Col>

                        <Col md={12} className="home-footer">
                            <a href="https://mrfreire.net" className="btn btn-primary" target="_blank" > Blog </a>
                            <a href="https://finance.mrfreire.net" className="btn btn-secondary" target="_blank">Finance</a>
                            <a href="https://nba.mrfreire.net" className="btn btn-three" target="_blank">NBA</a>
                            <a href="https://cycling.mrfreire.net" className="btn btn-four" target="_blank">Cycling</a>

                        </Col>

                    </Row>
                </Container>
            </Container>

                                   <Container fluid className="home-section" id="home">
                <Container className="home-content">
                    <Row>
                    </Row>
                </Container>
            </Container>
ﬁ
        </section>
    );
}

export default Home;
