import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {AiFillGithub, AiFillInstagram, AiOutlineMedium,} from "react-icons/ai";
import {FaLinkedinIn, FaStrava} from "react-icons/fa";

function Footer() {
    let date = new Date();
    let year = date.getFullYear();
    return (
        <Container fluid className="footer">
            <Row>
                <Col md="4" className="footer-copywright">
                    <h3>Javier Freire Álvarez</h3>
                </Col>
                <Col md="4" className="footer-body">
                    <ul className="footer-icons">
                        <li className="social-icons">
                            <a
                                href="https://github.com/vonfreiren"
                                style={{color: "white"}}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <AiFillGithub/>
                            </a>
                        </li>
                        <li className="social-icons">
                            <a
                                href="https://javier-freire.medium.com/"
                                style={{color: "white"}}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <AiOutlineMedium/>
                            </a>
                        </li>
                        <li className="social-icons">
                            <a
                                href="https://www.linkedin.com/in/javfreire/"
                                style={{color: "white"}}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaLinkedinIn/>
                            </a>
                        </li>
                        <li className="social-icons">
                            <a
                                href="https://www.instagram.com/vonfreiren/"
                                style={{color: "white"}}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <AiFillInstagram/>
                            </a>
                        </li>

                        <li className="social-icons">
                            <a
                                href="https://www.strava.com/athletes/vonfreiren"
                                style={{color: "white"}}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaStrava/>
                            </a>
                        </li>
                    </ul>
                </Col>

                <Col md="4" className="footer-copywright">
                    <h3>Copyright © {year}</h3>
                </Col>
            </Row>
        </Container>
    );
}

export default Footer;
