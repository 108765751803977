import React, {useEffect, useState} from 'react';
import data from "./songs.json"

function Table() {
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        setTableData(data['songs']);
    }, []);

    const [filter, setFilter] = useState('');

    useEffect(() => {
        setTableData(data['songs']);
    }, []);

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredData = tableData.filter((item) => {
        return (item.artist.toLowerCase().includes(filter.toLowerCase()) || item.song.toLowerCase().includes(filter.toLowerCase()));
    }).slice(0, 3000)
        .sort((a, b) => b.popularity - a.popularity);

        const isMobile = window.innerWidth <= 768; // Example mobile width threshold

    const tableClassName = isMobile ? 'very-small' : 'small';



    return (<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Search"/>
        <p>
        </p>
        <table className={tableClassName} style={{color: "white", margin: "50px", alignContent: "center"}}>
            <thead>
            <tr>
                <th>Artist</th>
                <th>Title</th>
                <th>Popularity</th>
            </tr>
            </thead>
            <tbody className="small" style={{textAlign: 'left'}}>
            {filteredData.map((item, index) => (
                <tr key={index}>
                    <td style={{padding: '7px'}}>{item.artist}</td>
                    <td style={{padding: '7px'}}>
                        {item.link ? (
                            <a href={item.link}>{item.song}</a>
                        ) : (
                            item.song
                        )}
                    </td>
                    <td style={{padding: '7px', textAlign: 'right'}}>{item.popularity}</td>
                </tr>
            ))}
            </tbody>

        </table>
    </div>);
}

export default Table;