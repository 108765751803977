import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
    return (<Card className="quote-card-view">
        <Card.Body>
            <div style={{textAlign: 'left'}}>
                <h2>Introduction</h2>
                <blockquote className="small">
                    <p>The website https://nba.mrfreire.net/ provides information about NBA teams, players, and their
                        market values. It includes details such as the names of the teams, their market values in
                        millions of dollars, and player information including their names, positions, heights, weights,
                        dates of birth, and team affiliations.
                    </p>
                    <p>
                        The website is a valuable resource for NBA enthusiasts
                        and analysts looking to gather information and insights about the league. </p>

                    <h2>Market value calculation</h2>
                    <p>
                        The market value is calculated considering the following factors:
                        <ul>
                            <li>Player statistics</li>
                            <li>Team statistics</li>
                            <li>Player age</li>
                            <li>Player position</li>
                            <li>Player height</li>
                            <li>Player weight</li>
                            <li>Player team</li>
                            <li>Progression</li>
                            <li>Consistency</li>
                            <li>Trends</li>
                            <li>Playoff results</li>
                        </ul>


                    </p>
                    <h2>Machine Learning</h2>
                    With the historic stats of active players and the historic players, the machine learning model tries
                    to predict the stats for the next season. The model takes into account the following factors:
                    <ul>
                        <li>Player statistics</li>
                        <li>Team statistics</li>
                        <li>Player age</li>
                        <li>Draft number</li>
                        <li>Draft round</li>
                        <li>Consistency</li>
                        <li>Games played</li>
                        <li>Experience</li>
                        <li>Player position</li>
                        <li>Player height</li>
                        <li>Player weight</li>
                        <li>Player team</li>
                        <li>Progression</li>
                        <li>Trends</li>
                        <li>Playoff results</li>
                    </ul>

                    With these predicted values, the market value calculation for the next season is also calculated.
                </blockquote>
            </div>
        </Card.Body>
    </Card>);
}

export default AboutCard;
