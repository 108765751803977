import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import {BsGithub, BsLink} from "react-icons/bs";

function ProjectCards(props) {
  return (
    <Card className="project-card-view">

      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify", fontSize: "12px"}}>
          {props.description}
        </Card.Text>
        <Button variant="primary" href={props.link} target="_blank" rel="noopener noreferrer">
           &nbsp;
          {props.isBlog ? "Blog" : "Link"}
        </Button>
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
