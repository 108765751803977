import React, {useEffect, useState} from 'react';

function TableMovie({data}) {
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        setTableData(data['songs']);
    }, []);

    const [filter, setFilter] = useState('');

    useEffect(() => {
        setTableData(data['movies']);
    }, []);

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredData = tableData.filter((item) => {
        return (item.title.toLowerCase().includes(filter.toLowerCase()) || item.genres.toLowerCase().includes(filter.toLowerCase()));
    }).slice(0, 3000);

    const isMobile = window.innerWidth <= 768; // Example mobile width threshold

    const tableClassName = isMobile ? 'very-small' : 'small';


    return    ( <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Search" />
        <p></p>
        <table className={tableClassName} style={{ color: "white" }}>
            <thead>
                <tr style={{ padding: '7px' }}>
                    <th style={{ padding: '7px' }}>Title</th>
                    <th style={{ padding: '7px' }}>Genres</th>
                    <th style={{ padding: '7px' }}>Rating</th>
                    <th style={{ padding: '7px' }}>Release</th>
                </tr>
            </thead>
            <tbody className="small" style={{ textAlign: 'left' }}>
                {filteredData.map((item, index) => (
                    <tr key={index}>
                        <td style={{ padding: '7px' }} dangerouslySetInnerHTML={{ __html: item.title }}></td>
                        <td style={{ padding: '7px' }}>{item.genres}</td>
                        <td style={{ padding: '7px' }}>{item.imdb_rating}</td>
                        <td style={{ padding: '7px', textAlign: 'right' }}>{item.year}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>);
}

export default TableMovie;