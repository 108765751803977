import React from "react";
import Card from "react-bootstrap/Card";

function TechCard() {
    return (<Card className="quote-card-view">
        <Card.Body>
            <div style={{textAlign: 'left'}}>
            <blockquote className="small">
                    <h2>Python</h2>
                    <p>Python is used for most of the operations and calculations that take place in real
                        time or in the background (executed via scripts in AWS). It's used in order to get
                        the financial data, do the different
                        calculations and passing this information into the Flask forms. For collecting the
                        data, there are several scripts running in order to feed the MongoDB database .
                    </p>

                    <h2>MongoDB</h2>
                    <p>The financial data is stored in a MongoDB database. Different collections are used to
                        store specific information about the assets, crypto information, sustainability
                        rankings, price or fund holdings. In addition to the information for each asset
                        or the prices, slower calculations such as the portfolio calculations are also
                        stored in this
                        database. The reason is that these calculations take some time since a lot of assets
                        and prices
                        are analyzed before building the different portfolio packs </p>

                    <h2>AWS - Amazon Web Services</h2>
                    <p>
                        AWS is used to host the application in an EC2 instance. In addition to this
                        instance, daily data
                        is retrieved and loaded into the databases using python scripts executed on the EC2
                        instances.
                    </p>

                    <h2>Gunicorn</h2>
                    <p>
                        Gunicorn is used as WSGI HTTP server to allow to run the Python web applications
                        behind a reverse proxy server. It is used because it's designed to be
                        lightweight, fast, and reliable, and is commonly used to deploy web applications in
                        production environments.
                        If the instance is also upgraded to a more powerful one, it can be used to run
                        additional workets and threads.
                    </p>
                    <h2>Nginx</h2>
                    <p>
                        Nginx is the web server software that is used as a reverse proxy, load
                        balancer, and HTTP cache. It is designed to efficiently handle large amounts of
                        traffic and is used by many high-traffic websites and web applications.

                        One of the main features of Nginx is its event-driven, asynchronous architecture,
                        which allows it to handle multiple concurrent connections efficiently. Nginx can
                        serve static content directly from disk, freeing up application servers to focus on
                        processing dynamic requests. It also includes a range of advanced features such as
                        request and response filtering, rate limiting, and SSL/TLS termination, making it a
                        versatile tool for a variety of use cases.
                    </p>
                </blockquote>

            </div>

        </Card.Body>
    </Card>)
        ;
}

export default TechCard;
