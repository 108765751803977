import React, {Component} from "react";
import Table from "./Table";
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";

class Songs extends Component {
    render() {
        return (
            <Container fluid className="project-section">
                <Container><h1 className="project-heading">
                    <strong>Music Library </strong>
                </h1>
                    <Row style={{justifyContent: "center", paddingBottom: "10px"}}>
                        <Col md={12} sm={6}>
                            <div style={{overflow: "auto", alignContent:"center"}}>
                                <Table style={{color: "white"}}/>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </Container>
        );
    }
}

export default Songs;