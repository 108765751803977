import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
    return (<Card className="quote-card-view">
        <Card.Body>
            <div style={{textAlign:'left'}}>
            <h2>Introduction</h2>
            <blockquote className="small">
               <p>This is a website in beta version focused on tracking, discovering and optimizing your
                                portfolio. At the moment, it just includes some basic features with the goal of teaching
                                people about finance. Please note that the website is not optimized for mobile devices.
                                Besides,
                                since there is a lot of data in each section, mobile devices may not be the best device
                                to
                                capture all the information.
                            </p>
                            <p>
                                If you are planning to invest, you can search for any asset here. Furthermore, you can
                                also use the RoboAdvisor feature with the desired investment amount and your
                                risk-aversion. In addition to the expected returns, it takes other factors such as
                                expenses, Morningstar analysis, or sustainability ratings </p>
                            <p>
                                The goal of this website is to help "common people" showing them information about
                                different
                                asset classes in order to educate them and help them protect their savings with their
                                right
                                choice.
                                This page should not be seen as financial advice at all.
                            </p>
                            <h2>Existing Features</h2>
                            <p>Some of these current features in the website available are:</p>
                            <p>
                            <ul>
                                <li>Robo-Advisor</li>
                                <li>Portfolio Tracking & Optimization</li>
                                <li>Asset Information</li>
                                <li>Comparator & Correlation</li>
                                <li>Recommended Buy Assets</li>
                                <li>Stock & Fund Portfolio Packs</li>
                                <li>Crypto Portfolio Packs</li>
                                <li>Alternative Assets & Real Estate</li>
                                <li>Funds Holding Stocks</li>
                                <li>Sustainability</li>
                                <li>VADER Sentiment Analysis</li>
                            </ul>
                            <p>The goal is to provide clear and transparent information about each asset class,
                                including correlated
                                assets, analyst recommendations, financial statements or historic returns.
                                For stocks, information such as the recommendation, financial statements or correlation
                                is included.
                                For investment funds and ETFS, data such as the Morningstar classification or the
                                expense ration are
                                also provided.
                                For crypto assets, in addition to the correlation and price information, links to the
                                CoinMarketCap
                                site and the utilities of this crypto asset are also included </p>
                            </p>
                            <h2>Other features</h2>
                            In addition to the features mentioned above focused on stocks, funds and crypto, the website
                            also includes other features such as:
                            <p>
                            <ul>
                                <li>Bond upcoming actions and yields</li>
                                <li>Commodities information</li>
                                <li>Markets information and news</li>
                                <li>Exchange rates data</li>
                                <li>Options</li>
                                <li>Carbon risk and fossil fuel exposure</li>
                            </ul>
                            </p>

                            <h2>Other information</h2>
                            <p>Please refer to the <strong>About</strong> section in order to know what's not possible
                                at the
                                moment. As you can see there are some limitations in the website and the data. With
                                time,
                                hopefully the list of problems gets reduced.
                            </p>

            </blockquote>
                </div>
        </Card.Body>
    </Card>);
}

export default AboutCard;
