import React, {Component} from "react";
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import TableMovie from "./TableMovie";
import data from "./series.json"

class Series extends Component {
    render() {
        return (
            <Container fluid className="project-section">
                <Container><h1 className="project-heading">
                    <strong>Series Watchlist </strong>
                </h1>
                    <Row style={{justifyContent: "center", paddingBottom: "10px"}}>
                        <Col md={12} sm={6}>
                            <div style={{overflow: "auto", alignContent:"center"}}>
                                <TableMovie data={data}  style={{color: "white"}}/>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </Container>
        );
    }
}

export default Series;