import React from "react";
import Typewriter from "typewriter-effect";

function TypeComing() {
    return (
        <Typewriter
            options={{
                strings: [
                    "Scenario Simulator",
                    "Whale Detector",
                    "Subscription & Alerts",
                    "Currency Optimization",
                    "Tax Optimization",
                    "More Assets",
                    "Fund Detailed Holdings"],
                autoStart: true,
                loop: true,
                deleteSpeed: 50,
            }}
        />
    );
}

export default TypeComing;
