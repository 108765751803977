import React from "react";
import Card from "react-bootstrap/Card";


function SetCard(props) {
    return (
        <a href={props.link} target="_blank" rel="noopener noreferrer">
            <Card className="set-card-view">
                <Card.Img src={props.imgPath} alt="card-img-set" className={"card-img-set"}/>
                <Card.Body className={"small"} style={{height: "50px", textAlign: "justify"}}>
                    <strong>{props.title}</strong> </Card.Body>
                <Card.Body className={"small"} style={{textAlign: "justify"}}>
                    <strong>{props.artist}</strong>
                </Card.Body>
                <p></p>
            </Card>
        </a>
    );
}

export default SetCard;
