import React, {useEffect, useState} from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer";
import Resume from "./components/Resume/ResumeNew";
import Books from "./components/Books/Books";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Concerts from "./components/Concerts/Concerts";
import Courses from "./components/Courses/Courses";
import Current from "./components/Current/Current";
import AboutFinance from "./components/Projects/Finance/About";
import TechFinance from "./components/Projects/Finance/Tech";
import LimitationsFinance from "./components/Projects/Finance/Limitations";
import ComingFinance from "./components/Projects/Finance/Coming";
import Songs from "./components/Songs/Songs";
import Series from "./components/Movies/Series";
import Movies from "./components/Movies/Movies";
import BooksToRead from "./components/Books/BooksToRead";
import Sets from "./components/Sets/Sets";
import AboutNBA from "./components/Projects/NBA/AboutNBA";
import ProjectOverview from "./components/Projects/ProjectOverview";

function App() {
    const [load, upadateLoad] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            upadateLoad(false);
        }, 1200);

        return () => clearTimeout(timer);
    }, []);

    return (<Router>
        <Preloader load={load}/>
        <div className="App" id={load ? "no-scroll" : "scroll"}>
            <Navbar/>
            <ScrollToTop/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/project" element={<Projects/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/resume" element={<Resume/>}/>
                <Route path="/books" element={<Books/>}/>
                <Route path="/concerts" element={<Concerts/>}/>
                <Route path="/courses" element={<Courses/>}/>
                <Route path="/current" element={<Current/>}/>
                <Route path="/songs" element={<Songs/>}/>
                <Route path="/movies" element={<Movies/>}/>
                <Route path="/series" element={<Series/>}/>
                <Route path="/books-wishlist" element={<BooksToRead/>}/>
                <Route path="/sets" element={<Sets/>}/>

                <Route path="/projects/finance/about" element={<AboutFinance/>}/>
                <Route path="/projects/finance/tech" element={<TechFinance/>}/>
                <Route path="/projects/finance/limitations" element={<LimitationsFinance/>}/>
                <Route path="/projects/finance/coming" element={<ComingFinance/>}/>

                                <Route path="/projects/nba/about" element={<AboutNBA/>}/>
                <Route path="/projects" element={<ProjectOverview/>}/>


                <Route path="*" element={<Navigate to="/"/>}/>

            </Routes>
            <Footer/>
        </div>
    </Router>);
}

export default App;
